var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('section',{staticClass:"services__card",attrs:{"id":"anchor"}},[_c('anchor-router-link',{staticClass:"services__card__one skewleft",attrs:{"to":{hash: '#priceweb'},"scrollOptions":{
                                  container: "body",
                                  duration: 700,
                                  easing: "ease",
                                  offset: 0,
                                  cancelable: true,
                                  onStart: false,
                                  onDone: false,
                                  onCancel: false,
                                  x: false,
                                  y: true}}},[_c('div',{staticClass:"services__card__content skewright"},[_c('i',{staticClass:"fas fa-laptop-code services__card__icon"}),_c('h2',{staticClass:"services__card__title"},[_vm._v("CREATION SITE WEB")])])]),_c('anchor-router-link',{staticClass:"services__card__one__center skewright",attrs:{"to":{hash: '#pricevid'},"scrollOptions":{
                                  container: "body",
                                  duration: 700,
                                  easing: "ease",
                                  offset: 0,
                                  cancelable: true,
                                  onStart: false,
                                  onDone: false,
                                  onCancel: false,
                                  x: false,
                                  y: true}}},[_c('div',{staticClass:"services__card_content center skewleft"},[_c('i',{staticClass:"fas fa-video services__card__icon"}),_c('h2',{staticClass:"services__card__title"},[_vm._v("PRODUCTION VIDEO")])])]),_c('anchor-router-link',{staticClass:"services__card__one skewleft",attrs:{"to":{hash: '#pricecrea'},"scrollOptions":{
                                  container: "body",
                                  duration: 700,
                                  easing: "ease",
                                  offset: 0,
                                  cancelable: true,
                                  onStart: false,
                                  onDone: false,
                                  onCancel: false,
                                  x: false,
                                  y: true}}},[_c('div',{staticClass:"services__card_content skewright"},[_c('i',{staticClass:"fas fa-paint-brush services__card__icon"}),_c('h2',{staticClass:"services__card__title"},[_vm._v("CREATION DE CONTENU")])])])],1),_c('section',{staticClass:"price"},[_c('h2',{staticClass:"price__title",attrs:{"id":"priceweb"}},[_vm._v("CREATION SITES WEB")]),_c('Tarifsweb'),_c('h2',{staticClass:"price__title",attrs:{"id":"pricevid"}},[_vm._v("PRODUCTION VIDEO")]),_c('Tarifsvideo'),_c('h2',{staticClass:"price__title",attrs:{"id":"pricecrea"}},[_vm._v("CREATION DE CONTENU")]),_c('Tarifscrea')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"homehead"}},[_c('h1',{staticClass:"maintitle"},[_vm._v("TARIFS")]),_c('p',{staticClass:"maintext"},[_vm._v("Que vous ayez le budget d'un bateau du Vendée Globe ou celui d'une petite barque, vous trouverez dans mes différents packs un tarif adapté à vos besoins.")])])}]

export { render, staticRenderFns }