<template>
  <div>
    <section id="homehead">
      <h1 class="maintitle">TARIFS</h1>      
      <p class="maintext">Que vous ayez le budget d'un bateau du Vendée Globe ou celui d'une petite barque, vous trouverez dans mes différents packs un tarif adapté à vos besoins.</p>
    </section>
    <section id="anchor" class="services__card">
      <anchor-router-link class="services__card__one skewleft" :to="{hash: '#priceweb'}" :scrollOptions="{
                                    container: `body`,
                                    duration: 700,
                                    easing: `ease`,
                                    offset: 0,
                                    cancelable: true,
                                    onStart: false,
                                    onDone: false,
                                    onCancel: false,
                                    x: false,
                                    y: true}">
              <div class="services__card__content skewright">
              <i class="fas fa-laptop-code services__card__icon"></i>
              <h2 class="services__card__title">CREATION SITE WEB</h2>
              </div>
      </anchor-router-link>
      <anchor-router-link class="services__card__one__center skewright" :to="{hash: '#pricevid'}" :scrollOptions="{
                                    container: `body`,
                                    duration: 700,
                                    easing: `ease`,
                                    offset: 0,
                                    cancelable: true,
                                    onStart: false,
                                    onDone: false,
                                    onCancel: false,
                                    x: false,
                                    y: true}">
        <div class="services__card_content center skewleft">
        <i class="fas fa-video services__card__icon"></i>
        <h2 class="services__card__title">PRODUCTION VIDEO</h2>
        </div>
      </anchor-router-link>
      <anchor-router-link class="services__card__one skewleft" :to="{hash: '#pricecrea'}" :scrollOptions="{
                                    container: `body`,
                                    duration: 700,
                                    easing: `ease`,
                                    offset: 0,
                                    cancelable: true,
                                    onStart: false,
                                    onDone: false,
                                    onCancel: false,
                                    x: false,
                                    y: true}">
        <div class="services__card_content skewright">
        <i class="fas fa-paint-brush services__card__icon"></i>
        <h2 class="services__card__title">CREATION DE CONTENU</h2>
        </div>     
      </anchor-router-link>
    </section>
    <section class="price">
    <h2 class="price__title" id="priceweb">CREATION SITES WEB</h2>
    <Tarifsweb/>
    <h2 class="price__title" id="pricevid">PRODUCTION VIDEO</h2>
    <Tarifsvideo/>
    <h2 class="price__title" id="pricecrea">CREATION DE CONTENU</h2>
    <Tarifscrea/>
    </section>
  </div>
</template>

<script>
import Tarifsweb from '../components/tarifsweb.vue';
import Tarifsvideo from '../components/tarifsvideo.vue';
import Tarifscrea from '../components/tarifscrea.vue';
import $ from 'jquery';
import AnchorRouterLink from 'vue-anchor-router-link'

export default {
  name: 'Home',
  components: {
    Tarifsweb,
    Tarifsvideo,
    Tarifscrea,
    AnchorRouterLink
  },
  mounted() {
        var rafId = null;
        var delay = 200;
        var lTime = 0;

        function scroll() {
          var scrollTop = $(window).scrollTop();
          var height = $(window).height()
          var visibleTop = scrollTop + height;
          $('.revealleft').each(function() {
            var $t = $(this);
            if ($t.hasClass('reveal_visible')) { return; }
            var top = $t.offset().top;
            if (top <= visibleTop) {
              if (top + $t.height() < scrollTop) {
                $t.removeClass('reveal_pending').addClass('reveal_visible');
              } else {
                $t.addClass('reveal_pending');
                if (!rafId) requestAnimationFrame(reveal);  
              }
            }
          });
          $('.revealright').each(function() {
            var $t = $(this);
            if ($t.hasClass('reveal_visible')) { return; }
            var top = $t.offset().top;
            if (top <= visibleTop) {
              if (top + $t.height() < scrollTop) {
                $t.removeClass('reveal_pending').addClass('reveal_visible');
              } else {
                $t.addClass('reveal_pending');
                if (!rafId) requestAnimationFrame(reveal);  
              }
            }
          });
        }
        function reveal() {
          rafId = null;
          var now = performance.now();
          
          if (now - lTime > delay) {
            lTime = now;
            var $ts = $('.reveal_pending');
            $($ts.get(0)).removeClass('reveal_pending').addClass('reveal_visible');  
          }
          
          
          if ($('.reveal_pending').length >= 1) rafId = requestAnimationFrame(reveal);
        }

        $(scroll);
        $(window).scroll(scroll);
  }
}
</script>

<style lang="scss">
.price {
  width: 100%;
  margin: auto;
  &__title {    
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 70px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 30px;
    position: relative;
    width: 90%;
    &::before {
      content: '';
    width: 100%;
    position: absolute;
    left: 0;
    top: calc( 50% - 1.5px );
    //border-image: linear-gradient(90deg, #38e01fab, #00000000) 20 / 0 0 10px 0;
    border-bottom: 30px solid #38e01fab;
    z-index: -1;
    }
  }
}

.maintitle {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-size: 8vw;
  color: white;
  margin-bottom: 0;
  @media (max-width: 1440px) {
		font-size: 8vw;
	}
  @media (max-width: 1024px) {
		font-size: 10vw;
	}
  @media (max-width: 768px) {
		font-size: 10vw;
	}
  @media (max-width: 425px) {
		font-size: 10vw;
	}
  @media (max-width: 300px) {
		font-size: 10vw;
	}
}

#homehead {
  width: 80%;
  margin: auto;
  height: 73vh;
}

.maintext {
  font-family: 'Open Sans', sans-serif;
  color: white;
  width: 60%;
  border-top: 6px solid white;
  padding-top: 24px;
  @media (max-width: 1440px) {
		font-size: medium;
    width: 60%;
	}
  @media (max-width: 1024px) {
		font-size: medium;
    width: 80%;
	}
  @media (max-width: 768px) {
		font-size: small;
    width: 80%;
	}
  @media (max-width: 425px) {
		font-size: small;
    width: 80%;
	}
  @media (max-width: 300px) {
		font-size: small;
    width: 80%;
	}
}

/******************** CARTES HAUT DE PAGE */

.services__card {
    background-image: url('../assets/wavej4.svg');
    background-size: auto;
    background-position-y: center;
    background-repeat: repeat-x;
    padding-top: 100px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  @media screen and (max-width: 980px){
            background-size: cover;
            flex-direction: column;
            padding-top: 50px;
            padding-bottom: 0;
            }
  &__container {
  display: flex;
  justify-content: space-evenly;
  box-sizing: border-box;
  width: 80%;
  margin: auto;
  }
  &__one {
    width: 25%;
    box-shadow: 0px 0px 6px 0px grey;
    border-radius: 50px;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    box-sizing: border-box;
    transition: all 0.25s;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    z-index: 50;
    //background: linear-gradient(127deg, #ffffff66 0%, #1967a314 90%, #ffffff66 110%);
    &:hover {
      transform: scale(1.03) skewY(13deg);
    }
    @media (max-width: 980px) {
      width: 90%;
      margin: auto;
      margin-bottom: 50px;
    }
    @media (min-width: 1440px) {
      padding: 50px 50px;
    }
    &__center {
       width: 25%;
    box-shadow: 0px 0px 6px 0px grey;
    border-radius: 50px;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    box-sizing: border-box;
    transition: all 0.25s;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    z-index: 50;
    //background: linear-gradient(127deg, #ffffff66 0%, #1967a314 90%, #ffffff66 110%);
      &:hover {
      transform: scale(1.03) skewY(-13deg);
    }
    @media (max-width: 980px) {
      width: 90%;
      margin: auto;
      margin-bottom: 50px;
      transform: skewY(13deg)!important;
      &:hover {
      transform: scale(1.03) skewY(13deg);
        }
      }
     @media (min-width: 1440px) {
      padding: 50px 50px;
    }
    }
  }
  &__title {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: xx-large;
    text-align: center;
  }
  &__icon {
  font-size: 400%;
  background: linear-gradient(308deg, #1c09e0cf 0%, #38e01fc7 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  }
}

.skewleft {
  transform: skewY(13deg);
  display: flex;
    flex-direction: column;
    align-items: center;
}

.skewright {
  transform: skewY(-13deg);
  display: flex;
    flex-direction: column;
    align-items: center;
}

.center {
   @media (max-width: 980px) {
      transform: skewY(-13deg)!important;
   }
}

/********* Apparition au scroll */

.revealleft {
  opacity: 0;
  transform: translate3d(-100px, 0, 0);
  transition: opacity 0.8s, transform 0.8s;
}

.revealright {
  opacity: 0;
  transform: translate3d(100px, 0, 0);
  transition: opacity 0.8s, transform 0.8s;
}

.reveal_visible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
</style>
